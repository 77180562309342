
.memorial-body div img {
    border-radius: 1.5em;
}

.memorial-body div {
    display: flex;
    justify-content: space-evenly;
    margin-left: 5px;
}
#image_1{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 0.95em;
    width:95%;
}
.memorial-data .memorial-body {
    display: flex;
    flex-direction: column;
    gap: .5em;
    overflow-x: auto;
}
.app-element audio-player-container{
    display:flex;
    flex-direction:column;
}
.wedding{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.width_265{
    width: 265px;
}
.ring{
    width: 60%;
    height: 80%;
}

#audio-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    flex-direction: column;
    font-size: 0.85rem;
    margin-bottom: 30px;
}
#image-container{
    display: flex; 
    /* justify-content: center; */
    width: 150%;
    height: auto;
    /* margin-left: 0px; */
    flex-direction: row;
    gap: 0.75em;
    z-index: 10;
    /* font-size: 0.85rem; */
    margin-bottom: 20px;
}

#audio-container title{
    font-size: 1.2em;
    font-weight: 600;
    color: hsl(var(--clr-bright-accent));
}
#audio-container audio{
    width: 100%;
    height: 40px;
    border-radius: 1em;
}
#img1{
    width: 60%;
    height: 100%;
    border-radius: 1.5em;
}
#img2{
    width: 30%;
    height: 100%;
    border-radius: 1.5em;
}
#img3{
    width: 60%;
    height: 90%;
    border-radius: 1.5em;
}
#img4{
    width: 85%;
    height: 100%;
    border-radius: 1.5em;
}

.memorial-data .memorial-title {
    color: hsl(var(--clr-bright-accent));
    font-size: 1.2em;
    font-weight: 600;
}

.memorial .memorial-data {
    grid-area: data;
    background-color: hsla(var(--clr-bright-shade), .5);
    width: 100%;
    height: 100%;
    z-index: 0;
    padding: 1em 1em 0;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    overflow: auto;
}

.datewise .date-note .plan-note {
    resize: vertical;
    /* height: auto; */
}

.datewise .thumbnail {
    border-radius: 1em;
}

.datewise .date {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    background-color: hsl(var(--clr-dark-accent));
    color: white;
    height: 4em;
    border-radius: 1em;
}

.datewise .date #date {
    flex-grow: 1;
    align-content: center;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
}

.datewise .date #next,
.datewise .date #prev {
    flex-grow: 0.3;
    color: white;
    border: none;
    outline: none;
}

.datewise .date #next {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

.datewise .date #prev {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.memorial .datewise {
    grid-area: date;
    background-color: hsl(var(--clr-bright-base));
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* align-items: flex-end; */
    overflow-x: auto;
    gap: 0.6em;
    padding-left: 4.2em;
    padding-right: 1.5em;
    padding-top: 1em;
    box-shadow: .2rem 0 var(--shadow-10);
    z-index: 1;
}

.memorial-content {
    height: -webkit-fill-available;
    width: 100%;
    background-color: hsl(var(--clr-bright-base));
    position: fixed;
}

.memorial {
    display: grid;
    grid-template-columns: var(--logo-space) 1fr;
    grid-template-areas: 'date data';
    /* column-gap: var(--spacing-inline); */
    width: 100%;
    height: 100%;
    position: relative;
    justify-items: center;
    align-items: center;
}
.flex-flex{
    align-items: center;
    flex-direction: column !important;
}
.desc_3{
    margin-bottom: 25px;
}