.post-content#modal {
    column-gap: var(--spacing-inline);
    overflow-y: hidden;
}

.post-content .likes{
    grid-area: left;
    overflow-y: auto;
    background-color: hsl(var(--clr-bright-base));
    padding: 1em;
    gap: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 1.6em;
    margin: 1em 0 1em 1em;
    box-shadow: .2rem 0 var(--shadow-10);
}

.post-content .likes .liked-user {
    border-radius: .6em;
    background-color: hsla(var(--clr-bright-shade),.5);
    padding: .6em 1em;
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    font-weight: 500;
    font-size: .85em;
    color: hsl(var(--clr-dark-accent));
}

.post-content .comments {
    grid-area: right;
    overflow-y: auto;
    background-color: hsl(var(--clr-bright-base));
    padding: 1em;
    gap: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 1.6em;
    margin: 1em 1em 1em 0;
    box-shadow: .2rem 0 var(--shadow-10);
}

.post-content .post{
    grid-area: center;
    /* box-shadow: .2rem 0 var(--shadow-10); */
    overflow-y: auto;
    padding-top: 2em;
    padding-bottom: 2em;
}

.post-content .comments .comment-content {
    border-radius: .6em;
    background-color: hsla(var(--clr-bright-shade),.5);
    padding: .6em 1em;
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    font-weight: 500;
    font-size: .85em;
    color: hsl(var(--clr-dark-accent));
    position: relative;
}

.post-content .comments .comment-content .delete-comment {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background-color: hsl(var(--clr-bright-accent));
    border-radius: inherit;
    padding: .21em;
    cursor: pointer;
}.post-content .comments .comment-content .delete-comment i {
    visibility: hidden;
    font-size: .5em;
}.post-content .comments .comment-content .delete-comment:hover i {
    visibility: visible;
    font-size: 1em;
}

.post-content .comments .comment-content .comment-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* width: 100%; */
}.post-content .comments .comment-content .comment-details .name {
    font-size: 1em;
    font-weight: 600;
    color: hsl(var(--clr-dark-accent));
    padding-right: .5em;
}.post-content .comments .comment-content .comment-details .time,
.post-content .comments .comment-content .comment-details .email {
    font-size: .9em;
}

.post-content .comments .comment-content .comment {
    width: 100%;
    font-size: 1.2em;
    padding: .4em;
    border: 1px solid hsl(var(--clr-bright-base));
    border-radius: inherit;
    margin-top: .4em;
    box-shadow: 0 0 .5em 0 hsla(var(--clr-dark-base), .5);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* overflow-x: auto; */
}.post-content .comments .comment-content .comment p {
    width: 90%;
    word-wrap:break-word;
    border-right: 1px solid hsla(var(--clr-bright-shade),.8);
    margin-right: .4em;
}.post-content .comments .comment-content .comment i:hover {
    color: hsl(var(--clr-bright-accent));
}

.post-content .comments .reply-content {
    margin-top: .8em;
    /* padding-left: .5em; */
    /* border-left: 1px solid hsl(var(--clr-dark-shade)); */
    display: flex;
    flex-direction: column;
}
.post-content .reply-content .reply-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 .5em;
    color: hsl(var(--clr-dark-accent));
}.post-content .reply-content .reply-buttons p:hover {
    font-weight: 600;
    cursor: pointer;
}

.post-content .reply-content form {
    box-shadow: inset 0 0 .4em -.15em hsl(var(--clr-dark-shade));
    display: flex;
    flex-direction: row;
    border-radius: .5em;
    margin-top: .5em;
    background-color: hsla(var(--clr-bright-base),.5);
}.post-content .reply-content form .reply-input {
    outline: 0;
    border: 0;
    width: 100%;
    padding: 1em;
}.post-content .reply-content form button {
    border-radius: inherit;
    font-size: 1.5em;
    border: 0;
    outline: 0;
}

.post-content .reply-content .replies {
    display: flex;
    flex-direction: column-reverse;
    max-height: 15em;
    padding-left: .5em; 
    border-left: 1px solid hsla(var(--clr-bright-shade),.9);
    margin-top: .5em;
    gap: .5em;
    overflow-y: auto;
}
.post-content .replies .reply {
    display: flex;
    flex-direction: column;
}.post-content .replies .reply header {
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    font-weight: 600;
}.post-content .replies .reply p {
    color: hsl(var(--clr-dark-accent));
}

.post-content .post .message {
    position: relative;
}

.post-content .post .message .close-post {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    font-size: 2em;
    padding: .4em;
    cursor: pointer;
}

.post-content .message form {
    box-shadow: inset 0 0 .4em -.15em hsl(var(--clr-dark-shade));
    display: flex;
    flex-direction: row;
    border-radius: .5em;
    margin-top: 1em;
}

.post-content .message form .comment-input{
    outline: 0;
    border: 0;
    width: 100%;
    padding: 1em;
}

.post-content .message form button {
    border-radius: inherit;
    font-size: 1.5em;
    border: 0;
    outline: 0;
}
.post-content .message form button:hover {
    color: hsl(var(--clr-bright-base));
}

#modal .comments .close-comment,
#modal .likes .close-likes
{
    display: none;
}

@media (max-width:40rem) {
    #modal .likes {
        grid-area: center;
        margin: 0;
        z-index: 1;
        padding-block: 3em;
    }

    #modal .comments {
        grid-area: center;
        margin: 0;
        z-index: 1;
        padding-block: 3em;
    }

    #modal .comments .close-comment,
    #modal .likes .close-likes {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        outline: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 1em;
        font-size: 1.5em;
        padding: .4em;
        cursor: pointer;
        z-index: 10;
    }
}