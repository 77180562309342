/* .invisible-scrollbar {
    overflow-anchor:none;
    overflow-y: hidden;
} */
@media (max-width: 1024px) {
    html body #user {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.post-image {
    width: 300px;
    height: 500px;
}

.profile {
    background-color: hsl(var(--clr-dark-accent));
    overflow-y: hidden;
    display: grid;
    grid-template-columns: var(--treeway-sizing);
    grid-template-areas: 'user inbox advertising';
    column-gap: var(--spacing-inline);
    position: fixed;
    height: -webkit-fill-available;
    /* z-index: 9; */
}

#user {
    padding-left: 40px !important;
    padding-right: 40px !important;
    align-items: center !important;
}

#img_outer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 122px;
}

#panel-profile-picture {
    width: 80%;
    border-radius: 50%;
}

.number {
    font-size: 17px;
}

#followers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text_sidebar {
    font-size: 12px;
    font-weight: 500;
}

.profile>#user {
    grid-area: user;
    position: fixed;
    height: 100%;
    right: auto;
    z-index: 999999;
    width: var(--logo-space);
    box-shadow: .2rem 0 var(--shadow-10);
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    font-size: 1.4rem;
    padding: var(--padding-block-start) var(--padding-inline) var(--spacing-block);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    /* overflow-y: scroll; */
}

.profile>#user>img.logo {
    position: fixed;
    inset: 0 0 auto;
    z-index: 9;
    background-color: inherit;
    color: hsl(var(--clr-bright-accent));
    font-size: var(--logo-fontsize);
    height: calc(var(--logo-height) * 1em + var(--padding-block-start) + var(--padding-block-end));
    padding: var(--padding-block-start) var(--padding-inline) var(--padding-block-end);
}

.profile>#user>img#panel-profile-picture {
    /* margin-top: calc(var(--padding-block-end) + var(--spacing-block) + (var(--logo-fontsize) * var(--logo-height))); */
    width: 100%;
    border-radius: .75rem;
}

.profile>#user>.name {
    color: hsl(var(--clr-bright-accent));
    font-size: 1.35em;
    font-weight: 800;
    text-transform: capitalize;
}

.profile>#user>.social {
    width: 85%;
    font-size: 1.1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
}

#left_margin_neg_35 {
    margin-left: -42%;
}
#panel-search-justify-top{
    padding-left: 40px !important;
    padding-right: 40px !important;
}

#left_margin_7 {
    margin-left: 7%;
}

.profile>#user>.social .bx {
    font-size: 1.8rem;
}

.profile>#user>.social>.people {
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: left;
}

.options_img {
    width: 25px;
    cursor: pointer;
}

.close_options {
    display: none;
    position: absolute;
    right: 0;
    top: -59px;
    cursor: pointer;
    color: black;
    font-weight: 500;
    width: 25px;
    font-size: 40px;
}

.profile>#user>.social>.people>.bx {
    margin-right: 0;
}

.profile>#user>.social>.dropdown {
    margin-left: auto;
    color: hsl(var(--clr-dark-accent));
    padding: 0;
}

.profile>#user>.social>.dropdown>.list {
    padding: .75em;
    gap: .5em;
}

.profile>#user>.social>.dropdown>.list>.element {
    color: hsl(var(--clr-dark-accent));
    font-size: 1.25em;
}

.profile>#user>#outer_left_margin_neg_35>.info>.info {
    font-size: 1.3em;
    font-weight: 250;
}
#outer_left_margin_neg_35{
    width: 85%;
}

.profile>#user>#outer_left_margin_neg_35>.info>.info>.tag {
    font-weight: 600;
    font-size: 1.1rem;
}

.profile>#user>.quote {
    font-size: 0.8em;
    font-weight: 600;
}

@media (min-height: 54.5rem) {
    .profile>#user {
        padding-bottom: calc(var(--padding-block-end) + (var(--logo-fontsize) * var(--logo-height)));
    }

    .profile>#user>img#panel-profile-picture {
        margin-top: auto;
    }

    .profile>#user>.quote {
        margin-bottom: auto;
    }
}

.relative-position {
    position: relative;
}

.optionsModal {
    display: none;
    position: absolute;
    background-color: white;
    border-radius: 10px;

    top: 60px;
    right: 3%;
    box-shadow: 2px 2px 20px -5px black;
}

.subOption {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 130px;
    padding: 15px;
    padding-right: 25px;
    cursor: pointer;
    transition: all 0.1s ease;
}

.subOption:hover {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.subOption img {
    width: 20px;
    margin-right: 5px;
}

.profile>.center {
    grid-area: inbox;
    height: 100%;
    background-color: hsl(var(--clr-dark-accent));
    padding-block: 0 2em;
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: 1.5rem;
    overflow-y: auto;
    position: relative;
    width: 110%;
}

.profile>.center>.pannel {
    box-shadow: 0 0 var(--shadow-01);
    border-radius: .75em;
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    font-size: 1.2rem;
    font-weight: 400;
    padding: 1.2em;
    display: flex;
    align-items: flex-end;
    flex-flow: wrap;
    gap: 1.5em max(1rem, 2vw);

}

.profile>.center>.pannel>.pannel-title {
    flex-basis: 100%;
    font-size: 1em;
    font-weight: inherit;
    width: auto;
}

.profile>.center>.pannel>.function {
    font-size: .8em;
    font-weight: inherit;
}

.profile>.center>.pannel>#share-function {
    font-size: .9em;
    font-weight: 500;
}

.options_post {
    width: 99%;
    position: relative;
    z-index: 999999999999999999;
}

.options_img {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -37px;
}

.profile>.center>.pannel>.pannel-title {
    grid-area: title;
    resize: vertical;
}

.delete {
    margin-left: 5px;
    margin-right: -19px;
    cursor: pointer;
}

.delete img {
    width: 45%;
    margin-top: 2px;
}

.deleteText {
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.yes_no_delete {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

#yes_button {
    padding: 12px 45px;
    background-color: #f10000;
    border-radius: 8px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 25px;
}

#no_button {
    padding: 12px 45px;
    background-color: #4e4e4e;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
}

.profile>.center>.pannel>#photo-function {
    grid-area: photo;
}

.profile>.center>.pannel>#audio-function {
    grid-area: audio;
}

.profile>.center>.pannel>#video-function {
    grid-area: video;
}

.profile>.center>.pannel>#share-function {
    grid-area: share;
    margin-left: auto;
    width: fit-content;
    border-radius: .45em;
    background-color: hsl(var(--clr-bright-accent));
    color: hsl(var(--clr-bright-base));
    padding: 0.3em 3em;
}

@media (max-width: 54.25rem) {
    .profile>.center>.pannel {
        row-gap: .5em;
    }

    .profile>.center>.pannel>.pannel-title {
        margin-bottom: 2em;
    }
   
}


.advertising {
    grid-area: advertising;
    height: 100%;
    background-color: hsl(var(--clr-dark-accent));
    padding-block: var(--spacing-block);
    padding-inline: var(--spacing-inline) var(--padding-inline);
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-left: 20px;
    /* scrollbar-width:none; */
}

.advertising div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 2em;
}

.advertising .ad {
    box-shadow: 0 0 var(--shadow-01);
    border-radius: 1rem;
    width: 100%;
}

/* ::-webkit-scrollbar {
    width: 12px;
} */

.mob_view_name{
    display: none !important;
}
.web_view_name{
    display: block !important;
}
.cursor-pointer{
    cursor: pointer;
}

@media (max-width: 40rem) {
    .profile {
        grid-template-columns: 1fr;
        /* grid-template-areas:
            'user'
            'advertising'
            'inbox'; */
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: 100%;
    }

    .profile>#user {
        position: static;
        inset: none;
        right: unset;
        z-index: unset;
        box-shadow: none;
        width: 100%;
        height: max-content;
        font-size: 1.05rem;
        padding-block: 2rem;
        padding-inline: var(--padding-inline);
        display: grid;
        grid-template-columns: unset;
        grid-template-areas:
            'picture name'
            'picture social'
            'picture info';
        align-items: center;
        justify-content: center;
        gap: .5rem 1rem;
        /* overflow: auto; */
    }
    .profile>#user{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mob_view_name{
        display: block !important;
    }
    .web_view_name{
        display: none !important;
    }
    #img_outer{
        margin-top: 0;
    }
    .mobile_view_user{
        display: none !important;
    }
    .profile>#user>img.logo,
    .profile>#user>.quote {
        display: none;
    }

    .profile>#user>img#panel-profile-picture {
        grid-area: picture;
        margin-top: 0;
        min-height: 8rem;
        min-width: 8rem;
    }

    .profile>#user>.name {
        grid-area: name;
    }

    .profile>#user>.social {
        grid-area: social;
        width: fit-content;
        justify-content: flex-start;
        gap: .25rem;
    }

    .profile>#user>.social>.people {
        margin-right: .35rem;
    }

    .profile>#user>.social>.dropdown {
        margin-left: .35rem;
        font-size: .75em;
    }

    .profile>#user>.social>.dropdown>.list {
        top: calc(100% + 1em);
        right: 0;
    }

    .profile>#user>.social>.dropdown>.list:not(.hidden) {
        display: flex;
    }

    .profile>#user>.info {
        grid-area: info;
    }

    .profile>.center {
        padding-block: 0;
        gap: .25rem;
        overflow: visible;
        height: auto;
    }

    .profile>.center>.pannel,
    .profile>.center>#post>.message {
        border-radius: 0;
        padding-inline: var(--padding-inline);
    }

    .profile>.center>#post {
        gap: .25rem;
    }
    #mobile_view_center{
        width: 100% !important;
    }

    .profile>.advertising {
        position: relative;
        height: max-content;
        width: 100%;
        padding: 2rem var(--padding-inline);
        flex-direction: row;
        gap: 2rem;
        height: 100%;
        overflow: visible;
        margin-left: 0 !important;
    }

    .profile>.advertising .ad {
        width: unset;
        height: max(8rem, 20vh);
    }

    .advertising div {
        flex-direction: row;
    }

    .profile .center .status-content {
        padding-inline: 1em;
    }
}

@media (max-width: 21rem) {
    .profile>.center>.pannel>#share-function {
        margin-left: unset;
    }
}

.profile .center .status-content {
    display: flex;
    height: 8em;
    width: 100%;
    overflow-x: auto;
    gap: 1.2em;
    align-items: center;
}

.profile .status-detail {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
}

.profile .status-detail div:not(.status-ring) {
    color: hsl(var(--clr-bright-base));
    font-size: .7em;
    font-weight: 500;
}

.profile .status-detail div:not(.status-ring-disabled) {
    color: hsl(var(--clr-bright-base));
    font-size: .7em;
    font-weight: 500;
}

.profile .status-ring {
    width: 6em;
    height: 6em;
    border-radius: 50%;
    border: 2px solid aqua;
    padding: .2em;
}

.profile .status-ring-disabled {
    width: 6em;
    height: 6em;
    border-radius: 50%;
    border: 2px solid rgb(126, 126, 126);
    padding: .2em;
}

.status-content .status-ring img {
    /* width: 100%; */
    height: 100%;
    border-radius: 50% !important;
}

.status-content .status-ring-disabled img {
    /* width: 100%; */
    height: 100%;
    border-radius: 50% !important;
}





/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 999999;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* Full width */

    overflow: auto;
    /* Enable scroll if needed */

    background-color: #252728;
    height: 100%;
    /* background-color: rgba(0,0,0,0.4);  */

}

.post_modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 999999;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* Full width */

    overflow: auto;
    /* Enable scroll if needed */

    background-color: #2527288c;
    height: 100%;
    /* background-color: rgba(0,0,0,0.4);  */
}

/* Modal Content */
.modal-content {
    position: relative;
    /* background-color: #979797; */
    background: linear-gradient(#212121, #424242, #212121);
    margin: auto;
    height: 85%;
    padding: 0;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-post {
    position: relative;
    /* background-color: #979797; */
    background: white;
    margin: auto;
    height: 20%;
    padding: 0;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.black-color {
    color: black !important;
}

#currentStory {
    width: 100%;
    position: relative;
    z-index: 999999;
}

#currentStoryAdd {
    width: 100%;
    position: relative;
    z-index: 999999;
    display: none;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 40px;
    font-weight: bold;
    font-weight: 500;
    margin-bottom: 2px;
    cursor: pointer;
    z-index: 9999999;
}


.modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

#scrollbarStory {
    /* border: 1px solid white; */
    border-radius: 10px;
    width: 97%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.scroll {
    border-width: 10px;
    margin-right: 15px;
    width: 100%;
    border: 1px solid rgba(179, 179, 179, 0.783);
    background-color: rgba(179, 179, 179, 0.783);
}

.scroll_inner {
    width: 0%;
    border: 1px solid white;
}

.modal-body {
    width: 100%;
}

#modal-body {

    position: relative;
}

#optionsStory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin-top: -15px;
}

#kaalabg {
    position: absolute;
    top: 0;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(38, 38, 38, .8) 0%, rgba(38, 38, 38, 0) 100%);
    padding-top: 30px;
    padding-left: 18px;

}

#image_of_user {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    z-index: 9999999;
}

#left, #right {
    display: flex;
    justify-content: center;
    align-items: center;
}

#nameStory {
    color: white;
    font-size: 14px;
    margin-left: 12px;
}

#createdAt {
    color: rgb(236, 236, 236);
    font-size: 14px;
    margin-left: 12px;
}

#text {
    position: absolute;
    bottom: 120px;
    width: 100%;
    background-color: #00000082;
    color: white;
    padding: 7px;
    text-align: center;
    z-index: 999999;
}

#your_story {
    position: relative;
}

#add-story {
    z-index: 999999999999999999999999999;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    right: 0px;
    cursor: pointer;
}

#blurbg1, #blurbg3 {
    height: 96%;
    width: 96%;
    position: absolute;
    filter: blur(10px);
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

#blurbg2, #blurbg4 {
    height: 96%;
    width: 96%;
    position: absolute;
    filter: blur(375px);
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.play {
    display: none;
}

.play img, .pause img {
    width: 21px;
    margin-right: 10px;
    margin-top: 1px;
    cursor: pointer;
}

#uploadImage {
    background-color: #ff5500;
    padding: 15px 25px;
    border-radius: 8px;
    color: white;
    outline: none;
    border: none;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    z-index: 9999999999999999999999999999999999999999999999999999999999999999;
}

#uploadImgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999999999999999999999999999999999999999;
    position: relative;
}

#addText {
    outline: none;
    border: none;
    color: white;
    width: 100%;
    text-align: center;
}

#sendStory {
    background-color: #ff5500;
    border-radius: 50%;
    padding: 20px;
    position: absolute;
    right: -20px;
    bottom: -20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sendStory img {
    width: 35px;
}

#storyDataOfStory {
    visibility: hidden;
}

#nextStory {
    position: absolute;
    right: -91px;
    bottom: 45%;
}

#nextStory img {
    width: 35px;
    cursor: pointer;
}

#prevStory img {
    width: 35px;
    cursor: pointer;
}

#prevStory {
    position: absolute;
    left: -91px;
    bottom: 45%;
}

@media only screen and (max-width: 600px) {
    .modal-content {
        width: 100%;
    }

    #prevStory {
        position: absolute;
        left: 7px;
        bottom: 45%;
        z-index: 999999;
    }

    #nextStory {
        position: absolute;
        right: 7px;
        bottom: 45%;
        z-index: 999999;
    }

    #sendStory {
        right: 10px;
        bottom: 10px;
    }
}