footer#pageInformations {
    position: relative;
    max-width: 100vw;

    box-shadow: 0 0 1rem -.1rem hsl(var(--clr-dark-shade));
    background-color: hsl(var(--clr-dark-accent));
    color: hsl(var(--clr-bright-shade));
    font-size: var(--fs-001);

    padding: 2.5em max(.5rem, 2vw);
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        'pages download  company'
        'pages socials   company'
        'pages watermark company';
    gap: 2rem max(1rem, 3vw);
}

footer#pageInformations>.container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: .5rem max(.5rem, 2vw);
}

footer#pageInformations>.container>.container-title {
    color: hsl(var(--clr-bright-shade));
    font-weight: 500;
    opacity: .6;
}

footer#pageInformations>.pages {
    grid-area: pages;
}

footer#pageInformations>.download {
    grid-area: download;
}

footer#pageInformations>.company {
    grid-area: company;
}

footer#pageInformations>.socials {
    grid-area: socials;
    padding-inline: .5rem;
    flex-direction: row;
    justify-content: space-around;
    gap: .5rem;
}

footer#pageInformations>.socials>.link {
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: hsl(var(--clr-bright-shade));
    color: hsl(var(--clr-dark-accent));
    font-size: 1.65em;
    font-weight: 900;
    padding: .35em;
    display: grid;
    place-items: center;
}

footer#pageInformations .social.link,
footer#pageInformations .social.link {
    transition: background-color var(--transition-out-smooth);
}

footer#pageInformations .social.link:hover,
footer#pageInformations .social.link:focus {
    transition: background-color var(--transition-in-smooth);
    background-color: hsl(var(--clr-bright-base));
}

footer#pageInformations>.watermark {
    position: absolute;
    bottom: -2.5em;
    inset-inline: auto;
    grid-area: watermark;
    background-color: hsl(225.71deg 49.61% 24.9%);
    text-align: center;
    padding: 1.5em 1em;
}

footer#pageInformations>.socials,
footer#pageInformations>.watermark {
    width: max(20vw, 25ch);
}

footer#pageInformations .link:not(.social.link):hover,
footer#pageInformations .link:not(.social.link):focus {
    color: hsl(var(--clr-bright-base));
}


@media (max-width: 18rem) {
    footer#pageInformations {
        padding-inline: 0;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas:
            '. pages .'
            '. download .'
            '. company .'
            '. socials .'
            'watermark watermark watermark';
        gap: 2rem max(1rem, 3vw);
        align-items: center;
        justify-content: center;
    }

    footer#pageInformations>.container:not(.socials, .watermark) {
        width: calc(75% - 10vw);
    }

    footer#pageInformations>.socials {
        margin-top: auto;
        justify-content: center;
        width: 100%;
        column-gap: max(5vw, 1em);
    }

    footer#pageInformations>.watermark {
        inset-inline: 0;
        width: 100%;
    }
}