.otp {
  padding-inline: 5vw;
  padding-bottom: 0%;
  height: 100%;
}
.otp-container button {
    background-color: hsl(var(--clr-bright-accent)) !important;
  }

  button:hover {
    background-color: rgb(255, 132, 70) !important;
  }

.otp-content {
  position: relative;
  height: 100%;
}

@media (max-width: 30rem) or (min-height: 110vw) {
  .otp {
    height: 100%;
  }
}

.otp-content .section-title {
  font-size: var(--fs-100);
  font-weight: 900;
}

.otp-content .section-subtitle {
  font-size: var(--fs-10);
  font-weight: 400;
}