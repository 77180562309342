header#appNavigation {
    position: static;
    top: 0;
    inset-inline: 0;
    z-index: 999;
    max-width: 100vw;
    background-color: hsl(var(--clr-dark-accent));
    color: hsl(var(--clr-bright-base));
    padding-block: var(--padding-block-start) var(--padding-block-end);
    padding-inline: 0 var(--padding-inline);
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: var(--navigation-sizing);
    grid-template-areas:
        'logo search navigation user';
    gap: 1.5rem var(--spacing-inline);
}

#logo_message{
    height: 48px !important;
}

header#appNavigation :is(#profile-link, #home-link, #inbox-link, #calendar-link, #hierarchy-link) {
    opacity: 1;
    transition: opacity var(--transition-out-smooth);
}

header#appNavigation :is(#profile-link, #home-link, #inbox-link, #calendar-link, #hierarchy-link):hover {
    transition: opacity var(--transition-in-smooth);
    opacity: .5;
}

header#appNavigation>#profile-link {
    grid-area: logo;
    margin-inline: auto;
    color: hsl(var(--clr-bright-base));
    font-size: var(--logo-fontsize);
    font-weight: bold;
    display: grid;
    justify-items: center;
    align-items: center;
}

header#appNavigation>#profile-link>img {
    filter: brightness(8) saturate(0) grayscale(100%);
    height: calc(var(--logo-height) * 1em);
}

header#appNavigation>#search-link {
    aspect-ratio: 1/1;
    border: none;
    color: inherit;
    font-size: 1.6rem;
    padding: 0;
    display: none;
}

header#appNavigation>.search {
    grid-area: search;
    width: 110%;
    border-radius: .65rem;
    background-color: hsla(var(--clr-bright-base), .25);
    color: hsl(var(--clr-bright-base));
    font-size: 1rem;
    padding: .65em 1em;
    display: flex;
    align-items: center;
}

header#appNavigation>.search>#search-button {
    aspect-ratio: 1/1;
    border: none;
    color: inherit;
    font-size: 1.5em;
    padding: 0;
}

header#appNavigation>.search>#search-button>.bx {
    font-size: 1em;
    padding: 0;
}

header#appNavigation>.search>#search-button:hover {
    cursor: pointer;
}

header#appNavigation>.search>#search-input {
    border: none;
    flex-grow: 99;
    color: hsl(var(--clr-bright-base));
    padding: 0;
}

header#appNavigation>.search>#search-input::placeholder {
    color: hsl(var(--clr-bright-shade));
}

header#appNavigation>.search>#search-input:focus {
    outline: 0;
    border: 0;
}

header#appNavigation>.navigation {
    grid-area: navigation;
    font-size: var(--navigation-height);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 90px;
    /* gap: 1rem; */
}

header#appNavigation>.navigation>.router-link-active {
    color: hsl(var(--clr-bright-accent-shade));
}

header#appNavigation>.navigation>.link {
    font-size: 1em;
}

header#appNavigation>.navigation>.link>.bx {
    font-size: 1em;
    padding-left: 5px;
    padding-right: 5px;
}

header#appNavigation>.user {
    grid-area: user;
    font-size: var(--user-height);
    display: flex;
    align-items: center;
    gap: 1.5rem var(--user-spacing);
    margin-left: 35px;
}

header#appNavigation>.user>#img-profile-link {
    height: 2.35em;
}

header#appNavigation>.user>#img-profile-link>#profile-picture {
    max-height: 100%;
}

header#appNavigation>.user>.dropdown {
    color: hsl(var(--clr-bright-base));
    font-size: 1em;
    padding: 0;
    gap: 0;
    margin: 0;
}

header#appNavigation>.user>.dropdown>.list {
    --spacing: 1.5;
    top: 250%;
    right: 0;
    color: hsl(var(--clr-dark-accent));
    padding: calc(1em * var(--spacing)) calc(2em * var(--spacing)) calc(1.25em * var(--spacing)) calc(1em * var(--spacing));
    gap: calc(.6em * var(--spacing));
}

header#appNavigation>.user>.dropdown>.list>.element {
    width: fit-content;
    gap: 1ch;
    transition: color var(--transition-out-smooth);
}

header#appNavigation>.user>.dropdown>.list>.element:hover,
header#appNavigation>.user>.dropdown>.list>.element:focus-visible {
    transition: color var(--transition-in-smooth);
    color: hsl(var(--clr-bright-accent));
    opacity: 1;
}

header#appNavigation>.hamburger-menu {
    aspect-ratio: 1/1;
    border: none;
    color: inherit;
    font-size: 1.6rem;
    padding: 0;
    display: none;
}

header#appNavigation>.hamburger-menu:active {
    scale: initial !important;
}

header#appNavigation>.hamburger-menu>i {
    font-size: 1.25em;
}

header#appNavigation>.hamburger-menu>.menu {
    position: absolute;
    inset: 0 0 auto auto;
    box-shadow: 0 0 calc(100vw + 100vh) calc(100vw + 100vh) hsla(var(--clr-dark-base), .7);
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    font-size: 1.2rem;
    padding-bottom: .75em;
    display: unset;
    z-index: 1;
}

header#appNavigation>.hamburger-menu>.menu.hidden {
    display: none;
}

header#appNavigation>.hamburger-menu>.menu>.element {
    position: relative;
    font-weight: 500;
    padding: .75em 2em;
}

header#appNavigation>.hamburger-menu>.menu>.element * {
    z-index: 1;
}

header#appNavigation>.hamburger-menu>.menu>.element:hover::before,
header#appNavigation>.hamburger-menu>.menu>.element:focus-visible::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: hsl(var(--clr-bright-shade));
    opacity: .35;
}
#language{
    border-radius: 10px;
    background-color: transparent;
    padding: 5px;
}
.language-selector{
    display: flex;
    justify-content: start;
    align-items: start;
    border-radius: 10px;
}
header#appNavigation>.hamburger-menu>.menu>.element:first-of-type,
header#appNavigation>.hamburger-menu>.menu>.element:last-of-type {
    margin-bottom: 1em;
}

header#appNavigation>.hamburger-menu>.menu>.user {
    background-color: hsl(var(--clr-bright-accent));
    color: hsl(var(--clr-bright-base));
    gap: .5em;
}

header#appNavigation>.hamburger-menu>.menu>.user>.profile-picture {
    height: 2.5em;
    padding: .25em;
}

header#appNavigation>.hamburger-menu>.menu>.user>.name {
    text-transform: capitalize;
}

header#appNavigation>.hamburger-menu .close-button {
    position: absolute;
    right: 100%;
    top: 0;
    border: none;
    width: fit-content;
    aspect-ratio: 1/1;
    color: hsl(var(--clr-bright-base));
    font-size: 2.5em;
    padding: .05em;
}

header#appNavigation>.hamburger-menu .close-button:hover {
    cursor: pointer;
}

header#appNavigation>.navigation>#search-link {
    display: none;
}



@media (max-width: 40rem) {
    header#appNavigation {
        display: flex;
        padding-inline: var(--padding-inline);
        column-gap: .25rem;
    }

    header#appNavigation>#profile-link {
        margin-right: auto;
        margin-left: 0;
        font-size: 1.25rem;
        padding: 0;
    }

    header#appNavigation>#search-link {
        display: unset;
    }

    header#appNavigation>.navigation {
        font-size: 1.6rem;
        margin-right: 5px;
        column-gap: 0.8rem;
        margin-top: 3px;
    }

    header#appNavigation>.navigation>#search-link {
        display:block;
    }

    header#appNavigation>.navigation>#hierarchy-link {
        display: none;
    }

    header#appNavigation>.search,
    header#appNavigation>.user {
        display: none;
    }

    header#appNavigation>.hamburger-menu {
        display: unset;
    }
}

.search-list{
    background-color: hsla(var(--clr-bright-base),.9) !important;
    position: absolute;
    gap: 1em;
    flex-direction: column;
    z-index: 10;
    top:4em;
    left:49.4%;
    transform: translate(-50%,0);
    width: 45% !important;
}
.search-user {
    display: flex;
    width: 100%;
    color:hsl(var(--clr-dark-accent));
    background-color: hsla(var(--clr-dark-base), .2);
    border-radius: .6em;
    padding: .5em .6em;
    cursor: pointer;
}

.search-user-profile {
    padding: .5em;
    border-radius: 100%;
    /* object-fit: contain; */
    background-color: #aaa;
    width: 4em;
}
.search-name {
    margin-left: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
}
.close-search-list {
    border: 0;
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: hsl(var(--clr-bright-accent));
    border-radius: inherit;
    cursor: pointer;
/* }.close-search-list i {
    visibility: hidden; */
}.close-search-list:hover i {
    visibility: visible;
    font-size: 1.4em;
}

@media (max-width:40rem) {
    header#appNavigation.show-search .search{
        display: flex;
    }
    header#appNavigation.show-search .search #search-button{
        display: none;
    }
    header#appNavigation.show-search>#profile-link {
        display: none;
    }
    header#appNavigation.show-search .search-list{
        /* margin-left: 22vw; */
        left: 34%;
        width: 58% !important;
    }
}