.side {
    overflow: auto;
}

.side-body .other-user:hover .accept-user {
    visibility: visible;
}

.side-body .other-user .accept-user {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: inherit;
    border-top-left-radius: inherit;
    background-color: green;
    outline: none;
    border: none;
    padding: .2em .5em;
    z-index: 3;
    visibility: hidden;
}

.side-body .other-user:hover .delete-user .rotated {
    rotate: 45deg;
}

.side-body .other-user:hover .delete-user {
    visibility: visible !important;
}

.side-body .other-user .delete-user {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    background-color: red;
    outline: none;
    border: none;
    padding: .2em .5em;
    z-index: 3;
    visibility: hidden;
}

.side-body .other-user {
    border-radius: .6em;
    background-color: hsla(var(--clr-bright-shade), .5);
    padding: .6em 1em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-weight: 500;
    color: hsl(var(--clr-dark-accent));
    position: relative;
}

.side .side-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    gap: 0.6em;
    padding: 0 .6em .8em;
    overflow-y: auto;
}

.side .side-nav {
    width: 100%;
    justify-content: space-evenly;
    position: relative;
    display: flex;
    top: 0;
}

.side .side-nav button {
    flex-grow: 1;
    outline: none;
    border: 0;
    height: 2.5em;
    font-weight: 600;
    color: hsl(var(--clr-dark-accent));
}

.side .side-nav button.disable {
    background-color: hsla(var(--clr-bright-shade), .5);
}


@media (max-width: 40rem) {
    .side .side-body {
        overflow-y: visible;
        min-height: 10em;
    }
    .side {
        overflow: visible;
    }
    .setting>.hidden-ffr{
        display: none;
    }
}
