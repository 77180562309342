@media (max-width: 40rem) {
    .setting .center {
        overflow-x: visible;
        padding: 1em;
    }
}

.scroll_edit {
    overflow: scroll;
    margin-bottom: 10%;
}
