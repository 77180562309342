.content {
    position: relative;
}

.Index_content {
    position: fixed;
    height: -webkit-fill-available;
    width: 100%;
    overflow: hidden;
}

.content>#sidebar {
    position: absolute;
    top: 5rem;
    left: 0;
    box-shadow: .2rem .2rem var(--shadow-1);
    border-top-right-radius: .85rem;
    border-bottom-right-radius: .85rem;
    background-color: hsl(var(--clr-bright-base));
    font-size: 1.1rem;
    padding: 2.1em 0.6em 1.3em 0.7em;
    display: flex;
    flex-direction: column;
    gap: .75em;
    z-index: 10;
}

.content>#sidebar>.link {
    font-size: 1em;
    display: flex;
    align-items: center;
    transition: color var(--transition-out-smooth);
}

.content>#sidebar>.link:hover,
.content>#sidebar>.link:focus-visible {
    transition: color var(--transition-in-smooth);
    color: hsl(var(--clr-bright-accent));
}
.content #sidebar:has(.shown) .toggle .bx {
    transform: rotateY(180deg);
}

.content #sidebar .link .hidden {
    display: none;
}

.content #sidebar .toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-left: auto;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: inherit;
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    padding: .5em;
    outline: none;
    border: none;
    font-size: 1rem;
    display: grid;
    place-items: center;
}

.familytree {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.familytree>.member {
    border-radius: 1em;
    width: fit-content;
    height: fit-content;
    background-color: hsl(var(--clr-dark-accent-shade));
    color: hsl(var(--clr-bright-base));
    font-size: .8rem;
    padding: 1em;
    padding-right: 2em;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'picture name'
        'picture title'
        'picture birth'
        'picture location'
        'picture status';
    gap: 0 1em;
}

.familytree>.member>.profile-picture {
    grid-area: picture;
    max-height: 7em;
    border-radius: .75em;
}

.familytree>.member>.name {
    grid-area: name;
    color: hsl(var(--clr-bright-accent));
    font-size: 1.15em;
    font-weight: 600;
}

.familytree>.member>.title {
    grid-area: title;
    margin-block: .6em;
    color: hsl(var(--clr-bright-accent));
    font-size: .9em;
}

.familytree>.member>.birth {
    grid-area: birth;
}

.familytree>.member>.location {
    grid-area: location;
}

.familytree>.member>.relationship-status {
    grid-area: status;
}

.familytree>.member>.info {
    font-size: .75em;
    font-weight: 300;
}

.familytree>.member>.info>.tag {
    font-weight: 500;
}

.Index_content #sidebar:has(.shown) .toggle .bx {
    transform: rotateY(180deg);
}

.Index_content #sidebar .link .hidden {
    display: none;
}

.Index_content #sidebar .toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-left: auto;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: inherit;
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    padding: .5em;
    outline: none;
    border: none;
    font-size: 1rem;
    display: grid;
    place-items: center;
}