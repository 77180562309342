.setting .center form {
    display: grid;
    margin-bottom: 10%;
    padding: 2em;
    width: 100%;
    border-radius: 1em;
    background-color: hsl(var(--clr-bright-base));
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "title title title"
        "profile profile public"
        "first_name middle_name last_name"
        "username username dob"
        "instagram facebook linkedin"
        "twitter youtube github"
        "city country m_s"
        "phone status status"
        "cancel save save";
    gap: 1em;
}

.setting .center form .title {
    grid-area: title;
    font-size: 2em;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: hsl(var(--clr-dark-accent));
}
#form_edit_profile{
    margin-top: 10%;
}
.setting .center form .profile-image {
    grid-area: profile;
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    cursor: pointer;
}

.setting .center form .profile-image img {
    width: 10em;
    height: 10em;
    padding: 0 !important;
}

.setting .center form .profile-image .edit-profile-picture {
    position: absolute;
    background-color: hsl(var(--clr-bright-base));
    font-size: 2em;
    bottom: 0;
}

.setting .center form .public {
    grid-area: public;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.4em;
    font-weight: 500;
    color: hsl(var(--clr-dark-shade));
}

.setting .center form input[placeholder*="First"] {
    grid-area: first_name;
}

.setting .center form input[placeholder*="Middle"] {
    grid-area: middle_name;
}

.setting .center form input[placeholder*="Last"] {
    grid-area: last_name;
}

.setting .center form .username {
    grid-area: username;
}

.setting .center form .dob {
    grid-area: dob;
}

.setting .center form .instagram {
    grid-area: instagram;
}

.setting .center form .facebook {
    grid-area: facebook;
}

.setting .center form .linkedin {
    grid-area: linkedin;
}

.setting .center form .twitter {
    grid-area: twitter;
}

.setting .center form .youtube {
    grid-area: youtube;
}

.setting .center form .github {
    grid-area: github;
}

.setting .center form .city {
    grid-area: city;
}

.setting .center form .country {
    grid-area: country;
}

.setting .center form .m_s {
    grid-area: m_s;
}

.setting .center form .phone {
    grid-area: phone;
}

.setting .center form .status {
    grid-area: status;
    resize: vertical;
}

.setting .center form .cancel {
    grid-area: cancel;
    border: 0;
    outline: 0;
    color: hsl(var(--clr-bright-base));
    border-radius: .5em;
    background-color: hsl(var(--clr-bright-accent));
    font-size: 1.5em;
    font-weight: 550;
}

.setting .center form .save {
    grid-area: save;
    border: 0;
    outline: 0;
    color: hsl(var(--clr-bright-base));
    border-radius: .5em;
    background-color: hsl(var(--clr-dark-accent));
    font-size: 1.5em;
    font-weight: 550;
}

.setting .center form .save:hover {
    background-color: hsla(var(--clr-dark-accent), .8) !important;
}

#profile-edit-input, #profile-edit-linkedin {
    width: 100%;
    box-shadow: inset 0 0 .4em -.15em hsl(var(--clr-dark-shade));
    border-radius: .75em;
    border: none;
    background-color: white;
    font-size: 1.2em;
    font-weight: 550;
    padding: .75em 1em;
    transition: box-shadow var(--transition-out-aggressive), background-color var(--transition-out-aggressive);
    outline: 0;
    color: hsl(var(--clr-dark-accent));
}

.setting .center form .public label {
    position: relative;
    width: 50px;
    height: 24px;
    background: #fff;
    display: inline-block;
    border-radius: 100px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.4);
}

.setting .center form .public label .fill {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 0;
    border-radius: 50%;
    background: hsl(var(--clr-dark-accent));
    width: 20px;
    height: 20px;
    transition: 0.4s left;
}

.setting .center form .public label input {
    width: 0;
    visibility: hidden;
}

.setting .center form .public label input:checked + span.fill {
    left: calc(100% - 22px);
    background: hsl(var(--clr-bright-accent));
}

.rotated-90 {
    rotate: 90deg;
}

@media (max-width: 40rem) {
    #private_text {
        margin-right: 7px;
    }
    #public_text {
        margin-left: 7px;
    }
    #form_edit_profile{
        display: flex;
        flex-direction: column;
    }
    #form_edit_profile>.profile-edit-textarea{
        height: 100px;
    }
    .setting .center form .profile-image {
        height: 10%;
    }
    .setting .center form .public {
        height: 4%;
    }
}
