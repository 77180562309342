/* Privacy_policy.css */

.privacy {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .privacy h1 {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .privacy p {
    margin-bottom: 15px;
    text-align: justify;
    font-size: 1rem;
    white-space: pre-wrap;
  }
  
  .privacy strong {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .privacy {
      padding: 15px;
    }
  
    .privacy h1 {
      font-size: 1.8em;
    }
  
    .privacy p {
      font-size: 0.95rem;
    }
  }
  
