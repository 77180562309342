.notification-content {
    width: 100%;
    height: -webkit-fill-available;
    display: grid;
    grid-template-columns: 1.5fr 2.5fr 1.5fr;
    grid-template-areas: 'left notification right';
    column-gap: var(--spacing-inline);
    position: fixed;
}

.notification-content .notification {
    grid-area: notification;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;
}.notification-content .pannel {
    background-color: hsla(var(--clr-bright-shade),.5);
    border-radius: 1em;
    padding: 1em;
    display: flex;
    color: hsl(var(--clr-dark-accent));
    align-items: center;
}.notification-content .pannel p {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 100%;
    font-size: 1.2em;
    align-items: center;
}.notification-content .pannel button {
    border:0;
    outline: 0;
    font-size: 2em;
    border-radius: 50%;
    cursor: pointer;
}.notification-content .pannel.unread {
    font-weight: 600;
}

.notification-content .right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}.notification-content .right button {
    border:0;
    outline: 0;
    font-size: 1.2em;
    border-radius: .5em;
    cursor: pointer;
    background-color: hsla(var(--clr-dark-accent),1);
    color: hsl(var(--clr-bright-base));
    padding: .5em;
}

@media (max-width: 40rem) {
    .notification-content {
        grid-template-columns: 1fr;
        grid-template-areas: 'right''notification';
        grid-template-rows: auto 1fr;
    }
    .notification-content .notification {
        padding: 1em 1em;
        border-top: 1px solid hsla(var(--clr-bright-shade),.7);
    }
}