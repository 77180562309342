.setting>.nav>img.logo {
    position: fixed;
    inset: 0 0 auto;
    z-index: 9;
    background-color: inherit;
    color: hsl(var(--clr-bright-accent));
    font-size: var(--logo-fontsize);
    height: calc(var(--logo-height) * 1em + var(--padding-block-start) + var(--padding-block-end));
    padding: var(--padding-block-start) var(--padding-inline) var(--padding-block-end);
}

#panel-search-justify-top {
    justify-content: start !important;
    align-items: center !important;
}

.setting>.nav>img#panel-profile-picture {
    margin-top: calc(var(--padding-block-end) + var(--spacing-block) + (var(--logo-fontsize) * var(--logo-height)));
    width: 100%;
    border-radius: .75rem;
}

#button-edit {
    margin-top: 40px;
    width: 65%;
}

#panel-search-profile-picture {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px !important;
}

.setting>.nav>.name {
    color: hsl(var(--clr-bright-accent));
    font-size: 1.55em;
    font-weight: 800;
    text-transform: capitalize;
}

#left_margin_neg_35 {
    margin-left: -42%;
}

.setting>.nav>.social {
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
}

.setting>.nav>.social .bx {
    font-size: 1.4rem;
}

#socials {
    width: 85% !important;
}

.setting>.nav>.social>.people {
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: left;
}

.setting>.nav>.social>.people>.bx {
    margin-right: 0;
}

.setting>.nav>.social>.dropdown {
    margin-left: auto;
    color: hsl(var(--clr-dark-accent));
    padding: 0;
}

.setting>.nav>.social>.dropdown>.list {
    padding: .75em;
    gap: .5em;
}

.setting>.nav>.social>.dropdown>.list>.element {
    color: hsl(var(--clr-dark-accent));
    font-size: 1.25em;
}

.setting>.nav>.info>.info {
    font-size: 1.3em;
    font-weight: 400;
}

.setting>.nav>.info>.info>.tag {
    font-weight: 600;
    font-size: 1.2rem;
}

.setting>.nav>.quote {
    font-size: 1.1rem;
    font-weight: 600;
}

.setting .nav .edit-profile {
    border: 1px solid hsl(var(--clr-dark-accent));
    border-radius: .4em;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6em .8em;
    font-weight: 500;
}

.setting .nav .edit-profile:hover {
    color: hsl(var(--clr-bright-base));
    border: 1px solid hsl(var(--clr-bright-base));
}

@media (max-width: 40rem) {
    .setting .nav {
        display: grid;
        grid-template-areas:
            'edit-profile edit-profile'
            'profile-picture name'
            'profile-picture social'
            'profile-picture info';
        width: 100%;
        padding-block: 2rem;
        padding-inline: var(--padding-inline);
        height: max-content;
    }

    .setting .nav .edit-profile {
        grid-area: edit-profile;
    }

    .setting .profile-picture {
        grid-area: profile-picture;
        width: 112px;
        height: 112px;
    }

    .setting>.nav>.name {
        grid-area: name;
    }

    .setting>.nav>.social {
        grid-area: social;
    }

    .setting>.nav>.info {
        grid-area: info;
    }

    .setting>.nav>.quote {
        display: none;
    }
    #panel-search-justify-top{
        display: flex !important;
    }
    #panel-search-justify-top>#panel-search-profile-picture{
        width: 45%;
        height: unset;
        margin-top: 15px !important;
    }
    #button-edit {
        margin-top: 10px;
    }
    
}