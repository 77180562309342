/* Terms_and_Conditions.css */

/* Styling for the content area of Terms and Conditions */
.tnc {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title styling */
  .tnc h1 {
    font-size: 1.8em;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
  }
  
  /* Paragraph styling */
  .tnc p {
    margin-bottom: 15px;
    text-align: justify;
    font-size: 1rem;
  }
  
  /* List styling */
  .tnc ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .tnc li {
    margin-bottom: 10px;
  }
  
  /* Link styling */
  .tnc a {
    color: #1d4ed8;
    text-decoration: none;
  }
  
  .content a:hover {
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .tnc {
      padding: 15px;
    }
  
    .tnch1 {
      font-size: 1.6em;
    }
  
    .tnc p {
      font-size: 0.95rem;
    }
  }
  
  