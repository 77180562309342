.album-content {
    background-color: hsl(var(--clr-dark-accent));
    width: 100%;
    height: -webkit-fill-available;
    display: grid;
    position: fixed;
    justify-content: center;
}
.album-content .album {
    max-width: 60vw;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
    overflow-x: auto;
    align-content: flex-start;
}

@media(max-width: 40rem) {
    .album-content .album {
        width: 100vw ;
        max-width: none;
    }
}

.album .album-pannel {
    width: 23%;
    aspect-ratio: 1/1;

}

.album .album-pannel>img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 1em;
    object-position: center;
}

.album .album-pannel>img:hover {
    /* aspect-ratio: auto; */
    object-fit: contain;
}

.album-content #sidebar:has(.shown) .toggle .bx{
    transform: rotateY(180deg);
}

.album-content #sidebar .link .hidden{
    display: none;
}

.album-content #sidebar .toggle{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-left: auto;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: inherit;
    background-color: hsl(var(--clr-bright-base));
    color: hsl(var(--clr-dark-accent));
    padding: .5em;
    outline: none;
    border: none;
    font-size: 1rem;
    display: grid;
    place-items: center;
}