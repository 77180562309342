.pwdrecovery-content {
    min-height: 85vh;
}

.password-recovery {
    padding-inline: 1rem;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'title title'
        'name surname'
        'email email'
        'button button'
}

@media (max-width: 52ch) {
    .password-recovery {
        grid-template-columns: 1fr;
        grid-template-areas:
            'title'
            'name'
            'surname'
            'email'
            'button';
    }
}

.password-recovery>.title {
    grid-area: title;
    color: var(--clr-dark-shade);
}

.password-recovery>#name-input {
    grid-area: name;
}

.password-recovery>#surname-input {
    grid-area: surname;
}

.password-recovery>#email-input {
    grid-area: email;
}

.password-recovery>#recover-password-button {
    grid-area: button;
}