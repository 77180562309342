/* Ads_policy.css */

/* General styling for the Ads policy section */
.ads {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title styling */
  .ads h1 {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Paragraph styling */
  .ads p {
    margin-bottom: 15px;
    text-align: justify;
    font-size: 1rem;
    white-space: pre-wrap;
  }
  
  /* Strong text styling within content */
  .ads strong {
    font-weight: bold;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .ads {
      padding: 15px;
    }
  
    .ads h1 {
      font-size: 1.8em;
    }
  
    .ads p {
      font-size: 0.95rem;
    }
  }
  