*{
    font-size:14px;
}
.chronicle-body div img{
    border-radius: 1.5em;
}
.chronicle-body div {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    gap: 0.7em;
}
#img1{
    width:60%;
}
#img2{
    width:40%;
}
.chronicle-data .chronicle-body{
    display: flex;
    flex-direction: column;
    gap: .5em;
    overflow-x: auto;
}
.chronicle-data .chronicle-title {
    color: hsl(var(--clr-bright-accent));
    font-size: 1.2em;
    font-weight: 600;
}
.chronicle .chronicle-data {
    grid-area: data;
    background-color: hsla(var(--clr-bright-shade),.5);
    width: 100%;
    height: 100%;
    z-index: 0;
    padding: 1em 1em 0;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    overflow: auto;
}
 
#wedding_text{
    margin-top: 2%;
    margin-right: 3%;
    margin-left: 0%;
    width: 96%;
    height: auto;
}
#milestone_text{
    margin-top: 1%;
    margin-right: 3%;
    margin-left: 0%;
    width: 96%;
    height: auto;
}

#ring_image{
    width: 60%;
    height: 80%;
    margin-top:20px;
    
} 
#ring_text{
    top:10%;
    width: 35%;
    margin-right:50px;
    margin-top:65px;
}

.datewise .date-note .plan-note {
    resize: vertical;
    height: auto;
}
.datewise .thumbnail {
    border-radius: 1em;
}
.datewise .date {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    background-color: hsl(var(--clr-dark-accent));
    color: white;
    height: 4em;
    border-radius: 1em;
}
.datewise .date #date {
    flex-grow: 1;
    align-content: center;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
}
.datewise .date #next, .datewise .date #prev {
    flex-grow: 0.3;
    color: white;
    border: none;
    outline: none;
}.datewise .date #next{
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}.datewise .date #prev{
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.chronicle .datewise{
    grid-area: date;
    background-color: hsl(var(--clr-bright-base));
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* align-items: flex-end; */
    overflow-x: auto;
    gap: 0.6em;
    padding-left: 4.2em;
    padding-right: 1.5em;
    padding-top: 1em;
    box-shadow: .2rem 0 var(--shadow-10);
    z-index: 1;
}

.chronicle-content {
    height: -webkit-fill-available;
    width: 100%;
    background-color: hsl(var(--clr-bright-base));
    position: fixed;
}

.chronicle {
    display: grid;
    grid-template-columns: var(--logo-space) 1fr;
    grid-template-areas: 'date data';
    /* column-gap: var(--spacing-inline); */
    width: 100%;
    height: 100%;
    position: relative;
    justify-items: center;
    align-items: center;
}
.flex-box{
    display: flex
    ;
        align-items: center;
        width: 100%;
        /* height: 100%; */
        padding: 0 1em;
        position: absolute;
        top: 4px;
        left: -2px;
}

.small-picture{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
