.setting {
    width: 100%;
    height: -webkit-fill-available;
    background-color: hsl(var(--clr-dark-accent));
    display: grid;
    justify-content: space-evenly;
    position: absolute;
    grid-template-columns: var(--treeway-sizing);
    grid-template-areas: 'nav center side';
    column-gap: var(--spacing-inline);
}

.setting .nav {
    position: relative;
    grid-area: nav;
    width: var(--logo-space);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: hsl(var(--clr-bright-base));
    box-shadow: .2rem 0 var(--shadow-10);
    color: hsl(var(--clr-dark-accent));
    font-size: 1.2rem;
    padding: var(--padding-block-start) var(--padding-inline) var(--spacing-block);
    gap: 1.5rem;
}

.setting .center {
    position: relative;
    grid-area: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    gap: 2em;
    padding: 1em 0;
}

.setting .side {
    position: relative;
    grid-area: side;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: hsl(var(--clr-bright-base));
    gap: .6em;
    box-shadow: .2rem 0 var(--shadow-10);
}


@media (max-width: 40rem) {
    .setting {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}