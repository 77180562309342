/* .invisible-scrollbar {
    overflow-anchor:none;
    overflow-y: hidden;
} */
.search-profile>.user>img.logo {
    position: fixed;
    inset: 0 0 auto;
    z-index: 9;
    background-color: inherit;
    color: hsl(var(--clr-bright-accent));
    font-size: var(--logo-fontsize);
    height: calc(var(--logo-height) * 1em + var(--padding-block-start) + var(--padding-block-end));
    padding: var(--padding-block-start) var(--padding-inline) var(--padding-block-end);
}

.search-profile>.user>img#panel-search-profile-picture {
    margin-top: calc((var(--logo-fontsize) * var(--logo-height)));
    width: 100%;
    border-radius: .75rem;
}

.search-profile>.user>.name {
    color: hsl(var(--clr-bright-accent));
    font-size: 1.35em;
    font-weight: 800;
    text-transform: capitalize;
}

.search-profile>.user>.social {
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
}

.search-profile>.user>.social .bx {
    font-size: 1.4rem;
}

.search-profile>.user>.social>.people {
    margin-right: auto;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: left;
}

.search-profile>.user>.social>.people>.bx {
    margin-right: 0;
}

.search-profile>.user>.social>.dropdown {
    margin-left: auto;
    color: hsl(var(--clr-dark-accent));
    padding: 0;
}

.search-profile>.user>.social>.dropdown>.list {
    padding: .75em;
    gap: .5em;
}

.search-profile>.user>.social>.dropdown>.list>.element {
    color: hsl(var(--clr-dark-accent));
    font-size: 1.25em;
}

.search-profile>.user>.info>.info {
    font-size: 1.1rem;
    font-weight: 400;
}

.search-profile>.user>.info>.info>.tag {
    font-weight: 700;
    font-size:1rem;
}

.search-profile>.user>.quote {
    font-size: 1rem;
    font-weight: 600;
}

@media (min-height: 54.5rem) {
    .search-profile>.user {
        padding-bottom: calc(var(--padding-block-end) + (var(--logo-fontsize) * var(--logo-height)));
    }

    .search-profile>.user>img#panel-search-profile-picture {
        margin-top: auto;
    }

    .search-profile>.user>.quote {
        margin-bottom: auto;
    }
}


#advertising {
    grid-area: advertising;
    height: 100%;
    background-color: hsl(var(--clr-dark-accent));
    padding-block: var(--spacing-block);
    padding-inline: var(--spacing-inline) var(--padding-inline);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
    overflow-y: auto;
    /* scrollbar-width:none; */
}

#advertising .ad {
    box-shadow: 0 0 var(--shadow-01);
    border-radius: 1rem;
    width: 100%;
}

/* ::-webkit-scrollbar {
    width: 12px;
} */

.search-profile .miscellaneous {
    position: relative;
    grid-area: miscellaneous;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: hsl(var(--clr-bright-base));
    gap: .6em;
    box-shadow: .2rem 0 var(--shadow-10);
}

.search-profile .center {
    position: relative;
    grid-area: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    gap: 2em;
    padding: 1em 0;
}

.search-profile .user {
    position: relative;
    grid-area: user;
    width: var(--logo-space);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: hsl(var(--clr-bright-base));
    box-shadow: .2rem 0 var(--shadow-10);
    color: hsl(var(--clr-dark-accent));
    font-size: 1.2rem;
    padding: var(--padding-block-start) var(--padding-inline) var(--spacing-block);
    gap: 1.5rem;
}

.search-profile {
    position: fixed;
    display: grid;
    width: 100%;
    height: -webkit-fill-available;
    justify-content: space-evenly;
    align-items: center;
    grid-template-columns: var(--treeway-sizing);
    grid-template-areas: 'user center miscellaneous';
    column-gap: var(--spacing-inline);
    background-color: hsl(var(--clr-dark-accent));
    /* z-index: 9; */
}

@media (max-width: 40rem) {
    .search-profile {
        grid-template-columns: 1fr;
        grid-template-areas:
            'user'
            'center'
            'miscellaneous';
        overflow: auto;
    }

    .search-profile>.user {
        position: static;
        inset: none;
        right: unset;
        z-index: unset;
        box-shadow: none;
        width: 100%;
        height: max-content;
        font-size: 1.05rem;
        padding-block: 2rem;
        padding-inline: var(--padding-inline);
        display: grid;
        grid-template-columns: unset;
        grid-template-areas:
            'picture name'
            'picture social'
            'picture info';
        align-items: center;
        justify-content: center;
        gap: .5rem 1rem;
        /* overflow: auto; */
    }

    .search-profile>.user>img.logo,
    .search-profile>.user>.quote {
        display: none;
    }

    .search-profile>.user>img#panel-search-profile-picture {
        grid-area: picture;
        margin-top: 0;
        min-height: 8rem;
        min-width: 8rem;
    }

    .search-profile>.user>.name {
        grid-area: name;
    }

    .search-profile>.user>.social {
        grid-area: social;
        width: fit-content;
        justify-content: flex-start;
        gap: .25rem;
    }

    .search-profile>.user>.social>.people {
        margin-right: .35rem;
    }

    .search-profile>.user>.social>.dropdown {
        margin-left: .35rem;
        font-size: .75em;
    }

    .search-profile>.user>.social>.dropdown>.list {
        top: calc(100% + 1em);
        right: 0;
    }

    .search-profile>.user>.social>.dropdown>.list:not(.hidden) {
        display: flex;
    }

    .search-profile>.user>.info {
        grid-area: info;
    }

    .search-profile>.center {
        padding-inline: 1em;
        gap: .5rem;
        overflow-x: visible;
    }

    .search-profile>.center>.pannel,
    .search-profile>.center>#inbox>.message {
        border-radius: 0;
        padding-inline: var(--padding-inline);
    }

    .search-profile>.center>#inbox {
        gap: .25rem;
    }

    .search-profile>#advertising {
        height: max-content;
        width: 100%;
        padding: 2rem var(--padding-inline);
        flex-direction: row;
        gap: 2rem;
        /*  overflow-x: hidden; 
        overflow-y: hidden;  */
    }

    .search-profile>#advertising .ad {
        width: unset;
        height: max(8rem, 20vh);
    }

    .search-profile .center .pannel .profile-picture {
        width: 15vw !important;
    }
}

@media (max-width: 21rem) {
    .search-profile>.center>.pannel>#share-function {
        margin-left: unset;
    }
}