header#pageNavigation {
    position: sticky;
    top: 0;
    inset-inline: 0;
    z-index: 999;
    max-width: 100vw;
    background-image: linear-gradient(to bottom,
            hsla(var(--clr-bright-base), .85) calc(100% - .8em),
            hsla(var(--clr-bright-base), .7) calc(100% - .45em),
            hsla(var(--clr-bright-base), .3) calc(100% - .125em),
            hsla(var(--clr-bright-base), .15) calc(100% - .075em),
            hsla(var(--clr-bright-base), .0) calc(100% - .05em),
            hsla(var(--clr-bright-base), .0));
    backdrop-filter: blur(3px);
    color: hsl(var(--clr-dark-base));
    padding: .5em 5vw 1.1em;
    display: flex;
    align-items: center;
    gap: 1rem max(1rem, 3vw);
}

header#pageNavigation > #home-link {
    margin-right: auto;
    color: hsl(var(--clr-bright-accent));
    font-size: 1.5rem;
    font-weight: bold;
    display: grid;
    align-items: center;
}

header#pageNavigation > #home-link > img {
    height: 2em;
}

header#pageNavigation > .pages {
    display: flex;
    align-items: baseline;
    gap: .5rem max(.5rem, 2vw);
}

@media (max-width: 23rem) {
    header#pageNavigation {
        flex-wrap: wrap;
        justify-content: center;
    }

    header#pageNavigation > #home-link {
        width: 100%;
        margin-right: unset;
        text-align: center;
        justify-self: center;
        justify-content: center;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown .list {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown .list li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown .list li:hover {
    background-color: #f1f1f1;
}

.hidden {
    display: none;
}