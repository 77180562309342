#outer_ai {
    display: flex;
    justify-content: center;
    align-items: center;
}
body{
    position: relative;
}
#left_ai {
    display: flex;
    width: 70%;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: start;
    padding-top: 50px;
}

#search {
    outline: none;
    border: 1px solid #f0f4f9;
    background-color: #f0f4f9;
    border-radius: 35px;
    width: 90%;
    padding: 10px 15px;
    font-size: 15px;
    padding-right: 35px;
}

#search::placeholder {
    color: #787878;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
}

#searchbar_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#searchbar{
    width: 100%;
}
.clear {
    position: absolute;
    right: 35px;
    font-size: 30px;
    font-weight: 300;
    cursor: pointer;
    top: 3px;
}

#map {
    background-color: rgb(237, 237, 237);
    width: 100%;
    height: 100vh;
}

#center_ai {
    width: 150%;
    height: 100%;
}

#right_ai {
    display: flex;
    width: 80%;
    flex-direction: column;
    height: 100vh;
}

#outer_result {
    height: 100%;
    overflow-y: hidden;
}

#inner_result {
    height: 100%;
    overflow-y: hidden;
}

#list_of_results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 89%;
    overflow-y: auto;
    list-style: none;
    padding-left: 0;
    margin-top: 25px;
}

.outer_result_box {
    padding: 20px 35px;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
}

.name_of_place {
    line-height: 20px;
    color: #202124;
    white-space: normal;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 9px;
}
#chat_nav{
    display: none;
}
#map_nav{
    display: none;
}
.vicinity {
    color: #787878;
    white-space: normal;
    text-align: left;

    font-size: 0.85rem;
}

.phone, .closed_open {
    color: #787878;
    white-space: normal;
    text-align: left;
    margin-top: 5px;
    font-size: 0.88rem;
}

.closed_open {
    margin-right: 5px;
}

.phone_closed_open {
    display: flex;
    justify-content: left;
    align-items: center;
}

.rating {
    color: #787878;
    white-space: normal;
    text-align: left;
    margin-top: 5px;
    font-size: 0.88rem;
    margin-right: 5px;
}

.rating_outer {
    display: flex;
    justify-content: left;
    height: 30px;
    align-items: center;
}

.stars {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    margin-top: 5px;
}

.reviews {
    color: #787878;
    white-space: normal;
    text-align: left;
    margin-top: 5px;
    font-size: 0.88rem;
}

.star img {
    width: 16px;
    height: 16px;
}

.red {
    color: red;
}

.green {
    color: green;
}

.a_tag {
    width: 100%;
}

#chatbout_outer {
    height: 80%;
    width: 100%;
}

#send_message {
    position: relative;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#send_button img {
    position: absolute;
    top: 42px;
    right: 40px;
    cursor: pointer;
}

#message {
    width: 90%;
    padding: 13px 17px;
    outline: none;
    border: 1px solid #f0f4f9;
    border-radius: 35px;
    font-size: 15px;
    padding-right: 55px;
    background-color: #f0f4f9;
    margin-bottom: 70px;

}
ul,ol,dl{
    padding-left:40px ;
}

#message::placeholder {
    color: #787878;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
}

#chatbot_outer {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

#chatbot_inner {
    height: 100%;
    width: 100%;
    padding: 30px 35px 0px 35px;
    overflow-y: auto;
}

.left_message {
    margin-bottom: 20px;
}

.top_of_message {
    display: flex;
    justify-content: left;
    margin-bottom: 7px;
    align-items: center;
}

.name {
    color: #676767;
    white-space: normal;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
}

.img_of_person {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.bottom {
    margin-left: 30px;
    line-height: 25px;
    font-size: 15px;
}

.preSearch {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    flex-direction: column;
    align-items: center;

}

.preSearchInner {
    width: 83%;
}
.preSearchInner input{
    height: 55px;
    width: 100% !important;
}
.preSearchInner span {
    top: 8px;
    right: 20px;
}
.preSearchHidden {
    display: none !important;
}
.loadingAI {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: hsl(var(--clr-bright-accent));
    font-weight: 700;
}

.rotated-45 {
    rotate: 45deg;
}
#loading{
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
}
#outer_result{
    width: 100%;
}
/* Mobile view */
@media only screen and (max-width: 600px) {
    #left_ai{
        width: 100%;
    }
    
    #center_ai,#right_ai{
        display: none;
    }
    #outer_ai{
        flex-direction: column;
    }
    #search{
        width: 330px;
    }
    #chat_nav,#map_nav{
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: #203060;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        bottom: 14px;
        right: 14px;
    }
    #chat_nav img,#map_nav img{
        width: 40%;
    }
    #map_nav{
        left: 14px;
    }
    #right_ai{
        width: 100%;
    }
    #message{
        margin-bottom: 140px;
    }
    #send_button img{
        top: -2px;
    }
    .text_hide{
        width: 92% !important;
    }
   
}
.hideAfter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.text_hide{
    margin-bottom: 50px;
    width: 800px;
    margin-top: 25px;
    line-height: 1.6rem;
    color: rgb(111, 111, 111);
    text-align: center;
}
.orange{
    font-size: 2.5rem;
    font-weight: 600;
    color: #ff5500;
}