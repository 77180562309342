.search-profile .center .pannel .user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: .85em;
}

.search-profile .center .pannel .user-profile {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

.search-profile .center .pannel {
    display: flex;
    flex-direction: column;
    background-color: hsl(var(--clr-bright-base));
    width: 100%;
    height:fit-content;
    padding: 1em 2em .8em;
    border-radius: 1em;
    /* gap: .5em; */
    font-size: 1.5em;
    justify-content: center;
    align-items: center;
    box-shadow: .2rem 0 var(--shadow-10);
}

.search-profile .center .pannel .profile-picture {
    width: 5em;
    height:5em;
    margin: 0 1em 0 0;
    border-radius: 50%;
    padding: .4em;
}

.search-profile .center .pannel div {
    display: flex;
    justify-content: stretch;
    align-items: center;
}
.search-profile .center .pannel div button {
    width: 100%;
    border:none;
    outline: none;
    background-color: hsla(var(--clr-dark-accent),.6);
    color: hsl(var(--clr-bright-base));
    font-size: 1em;
    border-radius: .5em;
    cursor: pointer;
    padding: .2em 1em;
}