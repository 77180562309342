.user-profile .center {
    position: relative;
    grid-area: center;
    background-color: hsl(var(--clr-bright-base));
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: .2rem 0 var(--shadow-10);
    border-radius: 2em;
    padding: 2em var(--spacing-inline);
    z-index: 12;
    overflow: hidden;
}
.user-profile .center .close-user-profile {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    font-size: 2.5em;
    padding: .4em;
    cursor: pointer;
}
.user-profile .center .close-user-profile .rotated{
    rotate: 45deg;
    font-weight: 500;
}
.user-profile .center .profile-pannel{
    display: flex;
    flex-direction: row;
    padding-bottom: 1em;
    border-bottom: 1px solid hsl(var(--clr-dark-accent));
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.user-profile .center .profile-pannel .profile-picture {
    border-radius: 50%;
    width: 12em;
    height: 12em;
}
.user-profile .center .profile-pannel .profile-detail-pannel {
    display: flex;
    flex-direction: column;
    font-size: 1.4em;
    height: 100%;
    justify-content: center;
}
.profile-detail-pannel .name {
    font-weight: 800;
    font-size: 1.5em;
    color: hsl(var(--clr-bright-accent));
    padding-bottom: .3em;
    
}

.user-profile .center .posts-pannel {
    margin-top: 1em;
    overflow: auto;
    padding: 1em;
}